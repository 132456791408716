<template>
  <v-dialog v-model="dialog" width="60%" hide-overlay min-width="600">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card style="height: 80vh;">
      <v-card-title>
        {{ target && target.refId }}
        <span v-if="targetMeasurement">({{ targetMeasurement }})</span>

        <v-btn class="ml-auto" color="primary" text @click="copy(curlComment)">
          <v-icon style="margin-right: 0.5em;">
            mdi-content-copy
          </v-icon>
          {{ $t('copy_comment') }}
        </v-btn>
      </v-card-title>
      <v-card-text
        class="pa-0"
        style=" overflow-y: auto;height: calc(100% - 114px);"
      >
        <CodeMirror ref="codeMirror" :value="curlComment" readonly />
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters>
          <v-spacer />
          <v-col cols="auto">
            <v-btn text @click="dialog = false">
              {{ $t('close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CodeMirror from '@/components/CodeMirror'

import settings from '@/assets/js/settings.js'
import CurlGenerator from 'format-curl'
import beautify from 'js-beautify'

import copyText from 'clipboard-copy'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { mapState } from 'vuex'

const beautfiyOptions = {
  indent_size: 4,
  brace_style: 'expand'

}
const beautifyJS = code => beautify(code, beautfiyOptions)

const urlApiInfluxData = `${settings.API_ENDPOINT}/api/influx/data`

// time string format
dayjs.extend(duration)
const now = dayjs()
const nowAfterWhile = now.add(dayjs.duration({ minutes: 2, seconds: 15 }))
const timestampToNanoseconds = timestamp => timestamp * 10 ** 6
const POINT_0_TIME = timestampToNanoseconds(+now)
const POINT_1_TIME = timestampToNanoseconds(+nowAfterWhile)
// const textPointTime = timestapm => `<user_keyin_nanoseconds_such_as_${timestapm}>`
const textPointTime = timestapm => timestapm
// time string format end

export default {
  name: 'CommentDialog',

  components: {
    CodeMirror
  },

  props: {
    target: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    dialog: false
  }),

  computed: {
    ...mapState({
      user: state => state.user.user,
      measurements: state => state.panels.form.measurements
    }),

    deviceToken() {
      return this.user?.unexpiredToken
    },
    targetMeasurement() {
      return this?.target?.measurement
    },
    targetSelect() {
      return this.target.select[0][0].params[0] || '<user_keyin>'
    },
    formatTags() {
      if (!this.target) return []

      return this.target.tags
        .filter(tag => tag.key)
        .map(tag => {
          const isTemplate = tag.isTemplate

          return {
            key: tag.key,
            value: isTemplate ? '<user_keyin>' : tag.value || ''
          }
        })
    },
    curlComment() {
      if (!this.target) return ''

      const measurementResourceId =
        this.measurements.find(
          m => m.measurementName === this.targetMeasurement
        )?.resource?.uuid || ''

      const apiParams = {
        method: 'POST',
        headers: {
          'Content-type': 'application/json;',
          Authorization: `Bearer ${this.deviceToken}`
        },
        body: beautifyJS(
          JSON.stringify({
            project_uuid: this.$route?.params?.projectId,
            resource_uuid: measurementResourceId,
            points: [
              {
                tags: this.formatTags,
                fields: [
                  {
                    key: this.targetSelect,
                    value: '<user_keyin>'
                  }
                ],
                time: textPointTime(POINT_0_TIME)
              },
              {
                tags: this.formatTags,
                fields: [
                  {
                    key: this.targetSelect,
                    value: '<user_keyin>'
                  }
                ],
                time: textPointTime(POINT_1_TIME)
              }
            ]
          })
        )
      }

      return CurlGenerator(urlApiInfluxData, apiParams)
    }
  },

  watch: {
    dialog(newVal) {
      if (!newVal) return

      // Refresh size when finish modal transition
      setTimeout(() => {
        this.$refs.codeMirror.refresh()
      }, 240)
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
