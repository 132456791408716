<template>
  <v-form ref="form">
    <v-row class="flex-nowrap" no-gutters>
      <!-- measurement col -->
      <v-col class="item-col" style="flex-basis: 330px; max-width: 19.573%;">
        <v-row class="item-title mb-4" no-gutters>
          <v-col cols="auto">
            {{ $t('page.panels.choose_measurement_title') }}
          </v-col>
          <v-spacer />
          <v-col v-if="project" cols="auto">
            <v-btn
              color="primary"
              icon
              small
              :disabled="isLoadingMeasurements"
              style="position: relative; bottom: 8px; left: 16px;"
              @click="fetchMeasurements"
            >
              <v-icon small>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pl-2" no-gutters>
          <v-col class="mb-3" cols="12">
            <v-select
              v-model="measurement"
              class="measurement-select"
              :items="measurementOptions"
              :loading="isLoadingMeasurements"
              solo
              hide-details="auto"
              :rules="rulesMeasurement"
            />
            <v-row
              v-if="selectedMeasurement"
              class="grey--text text-body-2 mt-1 pl-1"
              align="center"
              no-gutters
            >
              <v-col class="text-truncate" :title="selectedMeasurement.resource.uuid">
                {{ selectedMeasurement.resource.uuid }}
              </v-col>
              <v-col cols="auto">
                <v-btn
                  icon small
                  :title="$t('copy_uuid')"
                  @click="copy(selectedMeasurement.resource.uuid)"
                >
                  <v-icon small>
                    mdi-content-copy
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-alert
              class="measurement-tip pa-0"
              type="info"
              elevation="0"
              icon="mdi-information-outline"
              text
            >
              <span v-if="measurementOptions.length">{{ $t('page.panels.choose_measurement_content') }}</span>
              <span v-else>{{ $t('page.panels.no_measurement_and_join') }}</span>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>

      <!-- tags col -->
      <v-col class="item-col ml-3" style="flex-basis: 1106px; max-width: 65.6%;">
        <!-- tags title -->
        <v-row class="item-title mb-4" no-gutters align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col
                v-bind="attrs"
                class="d-flex align-center"
                cols="auto"
                v-on="on"
              >
                {{ $t('page.panels.add_tag_title') }}
                <v-icon class="ml-4" color="primary" dark>
                  mdi-information-outline
                </v-icon>
              </v-col>
            </template>
            <span>{{ $t('page.panels.add_tag_content') }}</span>
          </v-tooltip>
        </v-row>

        <v-row no-gutters style=" overflow-y: auto;max-height: 180px;">
          <v-col
            v-for="(tag, iTag) in target.tags"
            :key="iTag"
            class="mb-5"
            cols="12"
            style="min-height: 70px;"
          >
            <TagItem
              ref="tags"
              :tags="target.tags"
              :tag="tag"
              @remove="removeTag(tag)"
            />
          </v-col>
        </v-row>

        <!-- tags action -->
        <v-row class="mt-4" no-gutters align="center">
          <v-col cols="auto">
            <v-btn class="action-btn" color="secondary" @click="addTag">
              <v-icon size="16">
                mdi-plus-box
              </v-icon>
              <span class="ml-2">{{ $t('page.panels.add_tag') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- value col -->
      <v-col class="item-col ml-3" style="flex-basis: 250px; max-width: 14.827%;">
        <v-row class="item-title mb-4" no-gutters>
          {{ $t('page.panels.target_select') }}
        </v-row>
        <v-row no-gutters>
          <v-text-field v-model="targetSelect" solo dense />
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import TagItem from './TagItem'
import { validateRequired } from '@/assets/js/validate'
import { createTag } from '@/assets/js/grafana/model'

import copyText from 'clipboard-copy'

import { mapGetters, mapState } from 'vuex'

const rulesMeasurement = [validateRequired]

export default {
  name: 'TargetItem',

  components: {
    TagItem
  },

  props: {
    target: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    rulesMeasurement
  }),

  computed: {
    ...mapState({
      step: state => state.panels.form.step,
      stepPreview: state => state.panels.form.stepPreview,
      formMeasurements: state => state.panels.form.measurements,
      isLoadingMeasurements: state => state.panels.form.isLoadingMeasurements
    }),
    ...mapGetters({
      getProjectByRoute: 'projects/getProjectByRoute'
    }),

    project() {
      return this.getProjectByRoute(this.$route)
    },
    measurementOptions() {
      return this.formMeasurements.map(
        measurement => measurement.measurementName
      )
    },

    measurement: {
      get() {
        return this.target.measurement
      },
      set(newVal) {
        this.target.measurement = newVal
      }
    },
    selectedMeasurement() {
      return this.formMeasurements.find(measurement => measurement.measurementName === this.measurement)
    },
    targetSelect: {
      get() {
        if (!this.target) return ''

        return this.target.select[0][0].params[0]
      },
      set(newVal) {
        this.target.select[0][0].params.splice(0, 1, newVal)
      }
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    },
    measurementOptions: {
      handler() {
        if (this.measurement) return

        this.setDeafultMeasurement()
      },
      immediate: true,
      deep: true
    },
    step: {
      handler() {
        if (this.measurement) return

        if (this.step === this.stepPreview) {
          this.setDeafultMeasurement()
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    validate() {
      const refTags = this.$refs.tags
      if (!Array.isArray(refTags) || !refTags.length) {
        return this.$refs.form.validate()
      }

      return Promise.all([
        this.$refs.form.validate(),
        ...this.$refs.tags.map(el => el.validate())
      ]).then(results => results.every(Boolean))
    },
    resetValidation() {
      const refTags = this.$refs.tags
      if (!Array.isArray(refTags) || !refTags.length) {
        return this.$refs.form.resetValidation()
      }

      return [this.$refs.form, ...this.$refs.tags].forEach(el =>
        el.resetValidation()
      )
    },
    addTag() {
      const condition = this.target.tags.length === 0 ? '' : 'AND'
      const tag = createTag(condition)

      this.target.tags.push(tag)
    },
    removeTag(tag) {
      this.target.tags = this.target.tags.filter(t => t !== tag)
    },
    fetchMeasurements() {
      if (!this.project) return

      return this.$store.dispatch('panels/form/fetchMeasurements', {
        project: this.project
      })
    },
    setDeafultMeasurement() {
      if (
        !Array.isArray(this.measurementOptions) ||
        !this.measurementOptions.length
      ) {
        return
      }

      const firstOption = this.measurementOptions[0]

      if (typeof firstOption === 'string') {
        this.measurement = firstOption

        return
      }

      this.measurement = firstOption.value
    }
  }
}
</script>

<style lang="scss" scoped>
.action-btn.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  font-size: 1rem;
}

.item-col {
  padding: 16px 2rem;
  background-color: #fff;
  border: solid 0.8px #ddd;
  border-radius: 6px;

  .item-title {
    font-size: 1.375rem;
    color: #343843;
  }
}

.v-input.measurement-select {
  ::v-deep .v-input__control {
    min-height: 32px;

    .v-input__slot {
      min-height: 32px;
    }
  }
}

.measurement-tip.v-alert {
  font-size: 1rem;

  &::before {
    background-color: transparent;
  }

  ::v-deep .v-alert__wrapper {
    .v-alert__content {
      color: #343843;
    }
  }
}
</style>
