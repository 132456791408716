<template>
  <v-container class="panel-adder-container grey lighten-3 pa-0" fluid>
    <div
      class="wrap mb-3"
      style=" overflow-y: auto; height: calc(100% - 80px);"
    >
      <v-container class="pa-0" fluid style="height: 100%;">
        <v-stepper
          v-model="step"
          class="panel-adder-stepper"
          non-linear
          style="height: 100%;"
        >
          <v-stepper-header class="mx-auto my-3" style="width: 50%;">
            <v-stepper-step step="1" color="#759d30" :rules="rulesStep(1)">
              {{ $t('page.panels.step_gengeral_title') }}
              <small>{{ $t('page.panels.step_gengeral_content') }}</small>
            </v-stepper-step>

            <v-divider />

            <!-- <v-stepper-step step="2" color="#759d30" :rules="rulesStep(2)">
              綁定圖資
              <small>可選配置</small>
            </v-stepper-step> -->

            <v-divider />

            <v-stepper-step step="2" color="#759d30" :rules="rulesStep(2)">
              {{ $t('page.panels.step_preview_and_create_title') }}
              <!-- <small>可選配置</small> -->
            </v-stepper-step>
          </v-stepper-header>

          <v-progress-linear
            v-if="isLoading"
            class="mt-8"
            absolute
            indeterminate
            color="primary"
          />

          <v-stepper-items
            v-show="!isLoading"
            style="height: calc(100% - 72px);"
          >
            <v-stepper-content class="stepper-content" step="1">
              <PanelAdderGeneral ref="step1" />
            </v-stepper-content>

            <v-stepper-content class="stepper-content" step="2">
              <PanelAdderPreview ref="step2" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </div>

    <v-row class="stepper-action white" justify="end" align="center" no-gutters>
      <v-col cols="auto">
        <v-btn
          v-if="step > 1"
          class="mr-4"
          color="primary"
          :disabled="isSending || isLoading"
          @click="prevStep"
        >
          {{ $t('prev_step') }}
        </v-btn>
        <v-btn
          v-if="step < totalStep"
          color="primary"
          :disabled="isSending || isLoading"
          @click="nextStep"
        >
          {{ $t('next_step') }}
        </v-btn>
        <v-btn
          v-else
          color="primary"
          :disabled="isLoading"
          :loading="isSending"
          @click="submit"
        >
          <template v-if="isEditModel">
            <v-icon class="mr-2">
              mdi-content-save
            </v-icon>
            <span>{{ $t('save') }}</span>
          </template>
          <span v-else>{{ $t('create_panel') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PanelAdderGeneral from './PanelAdderGeneral'
import PanelAdderPreview from './PanelAdderPreview'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PanelAdder',

  components: {
    PanelAdderGeneral,
    PanelAdderPreview
  },

  props: {
    isLoadingProject: {
      type: Boolean,
      default: false
    }
  },

  data: vm => ({
    totalStep: 2,
    error: null,
    isSending: false,
    rulesStep: step => [() => vm.error !== step]
  }),

  computed: {
    ...mapState({
      isLoadingDashboards: state => state.dashboards.isLoadingDashboards
    }),
    ...mapGetters({
      getDashboardByRoute: 'dashboards/getDashboardByRoute',
      getPanelByRoute: 'dashboards/getPanelByRoute'
    }),

    isLoading() {
      return this.isLoadingDashboard || this.isLoadingProject
    },
    isEditModel() {
      return this.$route.name === 'PanelEditor'
    },
    dashboard() {
      return this.getDashboardByRoute(this.$route) || {}
    },
    editPanel() {
      return this.getPanelByRoute(this.$route)
    },
    isLoadingDashboard() {
      return (
        this.isLoadingDashboards === true ||
        this.isLoadingDashboards === this.$route.params.dashboardId
      )
    },
    hasNoDashboard() {
      return !this.dashboard.title
    },
    steps() {
      return Array.from({ length: this.totalStep }, (_, i) => `${i + 1}`)
    },
    step: {
      get() {
        return this.$store.state.panels.form.step
      },
      set(newVal) {
        this.$store.dispatch('panels/form/setState', {
          step: newVal
        })
      }
    }
  },

  created() {
    this.fetchDashboard()
      .then(() => {
        if (!this.isEditModel) {
          return this.init()
        }

        return this.setEditPanel()
      })
      .then(() => this.fetchTags())
  },

  methods: {
    fetchDashboard(loading = true) {
      if (this.isLoadingDashboard) return Promise.resolve()

      return this.$store.dispatch('dashboards/fetchDashboard', {
        projectId: this.$route.params.projectId,
        dashboardId: this.$route.params.dashboardId,
        loading
      })
    },
    fetchTags() {
      return this.$store.dispatch('panels/form/fetchTags', {
        projectId: this.$route.params.projectId,
        dashboard: this.dashboard
      })
    },
    init() {
      return this.$store.dispatch('panels/form/init').then(() => {
        this.resetValidation()
      })
    },
    setEditPanel() {
      if (!this.editPanel) return

      return this.$store.dispatch('panels/form/editPanel', {
        panel: this.editPanel
      })
    },
    resetValidation() {
      this.steps.map(step => {
        const ref = `step${step}`
        return this.$refs?.[ref]?.resetValidation?.()
      })
    },
    async formValidate(step) {
      const ref = `step${step}`
      const funValidate = this.$refs?.[ref]?.validate

      // to show error state
      this.error = null

      if (typeof funValidate !== 'function') {
        return true
      }

      const isValid = funValidate()

      if (!isValid) {
        this.error = step
      }

      return isValid
    },
    prevStep() {
      this.step -= 1
    },
    async nextStep() {
      const isValid = await this.formValidate(this.step)

      if (!isValid) return

      this.step += 1
    },
    async submit() {
      this.isSending = true

      const error = await this.fetchDashboard(false).catch(error => {
        this.isSending = false

        this.$store.dispatch('snackbar/showError', {
          content: error
        })

        return error
      })
      if (error) return

      const isValid = await Promise.all(
        this.steps.map(ref => this.formValidate(ref))
      ).then(results => {
        // go to error step when submit
        const firstErrorStep = results.findIndex(v => !v) + 1
        if (firstErrorStep) {
          this.error = firstErrorStep
          this.step = firstErrorStep
        }

        return results.every(Boolean)
      })

      if (!isValid) {
        this.isSending = false

        return
      }

      return this.$store.dispatch('panels/form/submit', {
        dashboard: this.dashboard,
        projectId: this.$route.params.projectId
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.isEditModel
              ? this.$t('success_edited')
              : this.$t('success_created')
          })

          this.init()

          return this.$router.push({
            name: 'Panels',
            params: {
              ...this.$route.params
            }
          })
        })
        .then(() => this.fetchDashboard())
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isSending = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-adder-container {
  height: calc(100vh - 64px);
}

.theme--light.v-stepper.panel-adder-stepper {
  background: transparent;
  box-shadow: none;

  .v-stepper__header {
    width: 1226px;
    box-shadow: none;

    .v-stepper__step {
      padding-top: 0;
      padding-bottom: 0;

      ::v-deep .v-stepper__step__step {
        width: 48px;
        min-width: 48px;
        height: 48px;
        font-size: 1.5rem;
      }

      ::v-deep .v-stepper__label {
        font-size: 1.375rem;
        font-weight: bold;
        color: #343843;

        small {
          margin-top: 2px;
          font-size: 0.75em;
          font-weight: normal;
          color: #343843;
        }
      }
    }
  }

  // .step-content {
  // }
}

.stepper-content {
  padding: 0;
  height: 100%;

  ::v-deep .v-stepper__wrapper {
    height: 100%;
  }
}

.stepper-action {
  padding-right: 72px;
  width: 100%;
  height: 68px;

  // position: absolute;
  // bottom: 0;

  & .v-btn.v-btn {
    padding: 4px 1.875rem;
    font-size: 1.25rem;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    letter-spacing: normal;
  }
}
</style>
